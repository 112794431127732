<template>
    <div>
        <b-table :empty-text="this.$t('there_are_no_records')" bordered striped responsive :items="attendances"
            :fields="attendancesFields" show-empty class="mb-5 table-dropdown no-scrollbar border rounded">
            <template #cell(hours)="data">
                {{ data.item.hours == 0 ? $t('attended') : $t('not_attended') }}
            </template>
        </b-table>
    </div>
</template>
<script>

import moment from "moment";

export default {
    name: "AttendanceDetailTable",
    props: {
        attendanceList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            attendances: [],
            attendancesFields: [
                {
                    key: 'week',
                    label: this.$t('week'),
                    tdClass: 'w-50 text-center',
                    thClass: 'd-flex justify-content-center align-center',
                },
                {
                    key: 'hours',
                    label: this.$t('attendance_hours'),
                    tdClass: 'w-50 text-center',
                    thClass: 'text-center'
                },

            ]
        }
    },
    watch: {
        attendanceList: {
            handler(value) {
                this.setData(value)
            }
        }
    },
    methods: {
        setData(value) {
            this.attendances = value

            this.attendances.forEach((itm, index) => {
                this.attendances[index].date = moment(itm.date).format('DD-MM-YYYY')
            })
        }
    }
}
</script>

